<template>

    <div class="h-auto rounded-lg flex flex-col p-2 mb-4 bg-white" :class="{'shadow_box cursor-pointer': selected}">
        <div class="h-16 flex flex-row"  @click="openComercial(data)">
            <div class="h-full w-2/12 flex justify-center items-center">
                <div class="h-12 w-12 rounded-full overflow-hidden">
                    <vue-initials-img :name="data.Name || data.ZonesName" class="h-12 w-12"/>
                </div>
            </div>
            <div class="h-full w-9/12 flex flex-col justify-center items-start pl-2 truncate">
                <span class="font-semibold text-dfont">{{data.Name || data.ZonesName}}</span>
                <span class="text-xs text-gray">{{$t('zone')}} {{data.ZonesName}}</span>
            </div>
            <div class="h-full w-1/12 flex flex-row justify-center items-center">
                <i class="mdi mdi-arrow-right font-semibold text-dfont"></i>
            </div>
        </div>

        <div class="flex-1 flex flex-col justify-between py-2" @click="openComercial(data)">
            <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm" :class="'text-font_gray-'+mode">{{$t('day')}}</span>
                <span class="text-sm font-bold text-dfont">{{data.Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="text-sm" :class="'text-font_gray-'+mode">{{$t('accumulated')}}</span>
                <span v-if="dimensionName == 'Precio medio'" class="text-sm" :class="'text-font_gray-'+mode">{{$t('averagePrice')}}</span>
                <span v-if="dimensionName == 'Margen'" class="text-sm" :class="'text-font_gray-'+mode">{{$t('margin')}}</span>
                <span class="text-sm font-bold text-dfont">{{data.Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}}</span>
                <span class="text-sm font-bold text-dfont">{{data.DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm" :class="'text-font_gray-'+mode">{{$t('scope')}}</span>
                <span class="text-sm font-bold text-dfont">{{data.MonthPreview | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm" :class="'text-font_gray-'+mode">Real {{yearant}}</span>
                <span class="text-sm font-bold text-dfont">{{data.PastYear | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm" :class="'text-font_gray-'+mode">{{$t('variation')}} {{periodo}}</span>
                <span class="text-sm font-bold text-dfont"><span v-if="data.MonthVariation >= 0">+</span>{{data.MonthVariation | reduceBigNumbers(2)}} <span v-html="unit3"></span></span>
            </div>
        </div>

        <div v-if="permisions" class="h-auto">
            <Request model="Companytocomercialmessage" action="create" :form="form" @success="onSuccess" @error="onError" v-slot="{ request, loading:loadingrequest }" class="w-full">
                <div v-if="!loadingrequest && data.Id" class="h-auto">
                    <div class="h-10 flex flex-row justify-start items-center px-4">
                        <div class="h-8 flex-1 pr-2" @keypress.enter="request">
                            <input ref="input" type="text" v-model="message" :placeholder="$t('writeMessage')"  class="w-full h-full bg-lightgray rounded-lg text-dfont px-2 shadow-inner">
                        </div>
                        <div @click="request" class="h-8 w-10 rounded-lg bg-red flex flex-row justify-center items-center">
                            <i class="mdi mdi-send text-white"></i>
                        </div>
                    </div>
                </div>
            </Request>
        </div>
    </div>

</template>

<script>
import { state, actions } from '@/store';
import { All, Request } from '@/api/components';

export default {
    props:['data', 'comercialSelected', 'key2', 'dimensionName', 'rol'],
    components: {
        All,
        Request
    },
    data() {
        return {
            yearant: this.$moment().subtract(1, 'years').format('YYYY'),
            message: null
        }
    },
    methods: {
        openComercial(data) {
            if(this.rol == 'Propiedad' || this.rol == 'Gerente' || data.Id == this.user.id) { this.$emit('openComercial', data) }
        },
        onSuccess() {
            this.message = null
            this.$message({
            message: this.$t("messageSentSuccessfully"),
            type: 'success'
            });
        },
        onError(error) {
            this.$message.error(this.$t("theMessageCouldNotBeSent"))
        }
    },
    computed: {
        unit() {
            let unit = '€'
            if (state.dimensionGroup == 1) { unit = 'm<sup>2</sup>' }
            if (state.dimensionGroup == 3) { unit = '%' }
            return unit
        },
        unit3() {
            let unit = '%'
            if (state.dimensionGroup == 2) { unit = ' €/m<sup>2</sup>' }
            if (state.dimensionGroup == 3) { unit = '' }
            return unit
        },
        user() {
            return state.user
        },
        selected() {
            if (state.user.rol.Name == 'Propiedad' || state.user.rol.Name == 'Gerente' || this.data.Id == this.user.id) { return true }
            else { return false }
        },
        form() {
            return {
                message: this.message,
                Company: state.idCompany,
                SaleType: state.saleTypeSelected,
                IdUser: this.data.Id
            }
        },
        permisions() {
            if (state.user.rol.Name == 'Gerente') {
                if (state.user.email == 'imilian@pamesa.com') { return true }
                else
                    if (state.user.email == 'msebastia@tauceramica.com') { return true }
                    else
                        if ( state.user.email == 'jpiquer@geotiles.com') { return true}
                        else { return false }
            }
            else { return false }
        }
    },
    mounted() {
    }
}
</script>