<template>
    <div class="h-auto flex flex-col justify-between px-4">

        <div class="h-3 rounded-full flex flex-row justify-start items-center relative mb-2 overflow-hidden" :class="'bg-bg-'+mode">
            <div class="h-full rounded-full transition shadow-lg"
                 :class="(current > prev) ? 'bg-good': 'bg-bad'"
                 :style="{width:  ( current > prev ) ? current+'%' : prev+'%' }"
                 >
            </div>
            <div class="h-full rounded-full absolute top-0 left-0 transition " :class="'bg-bg-'+mode" 
                 v-if="current > 0"
                 :style="{width:  ( current > prev ) ? (prev+.5)+'%' : (current+.5)+'%' }"
                 >
            </div>
            <div class="h-full rounded-full bg-red absolute top-0 left-0 transition" 
                 v-if="current > 0"
                 :style="{width:  ( current > prev ) ? prev+'%' : current+'%' }"
                 >
            </div>
        </div>

        <div class="h-3 rounded-full flex flex-row justify-start items-center overflow-hidden" :class="'bg-bg-'+mode">
            <div class="h-full rounded-full bg-sky shadow-lg" :style="'width:'+ prev +'%; transition:.5s;'"></div>
        </div>

    </div>

</template>

<script>
import { state } from '@/store';

    export default {
        props:['current','prev'],
        computed:{
            mode(){
                return state.mode
            }
        }
    }
</script>